:host {
  --notification-success-background: rgb(3, 151, 70);
  --notification-success-color: rgb(255, 255, 255);

  --notification-error-background: rgb(170, 4, 4);
  --notification-error-color: rgb(255, 255, 255);

  --notification-warn-background: rgb(165, 97, 8);
  --notification-warn-color: rgb(255, 255, 255);

  --notification-info-background: rgb(22, 57, 122);
  --notification-info-color: rgb(255, 255, 255);

  text-align: center;
  position: fixed;
  bottom: -5px;
  left: 12px;
  min-width: 169px;
  min-height: 32px;
  padding: 12px 16px !important;
  background: var(--notification-info-background, black);
  border-radius: 4px;
  color: var(--notification-info-color, white);
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-in-out;
  z-index: 200000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

:host([visible]) {
  opacity: 1;
  bottom: 12px;
  pointer-events: all;
}

:host([type='error']) {
  background: var(--notification-error-background, red);
}

:host([type='warn']) {
  background: var(--notification-warn-background, orange);
}

:host([type='success']) {
  background: var(--notification-success-background, green);
}
